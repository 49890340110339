.row-input-otp {
	display: flex;
	column-gap: 8px;

	.col-input-otp {
		width: calc(100% / 6);

		.wrapper-input {
			position: relative;
			display: flex;
			height: 0;
			width: 100%;
			padding-bottom: 100%;

			.input-otp {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				text-align: center;
				border-radius: 6px;
				border: 1px solid #393c3f;
				background: #000;
				box-shadow: none;
				outline: none;
				color: @white;
				text-align: center;
				font-size: 20px;
				font-weight: 700;

				/* Chrome, Safari, Edge, Opera */
				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}

				/* Firefox */
				&[type='number'] {
					-moz-appearance: textfield;
				}
			}
		}
	}
}


@import '/home/eff/app/creoplay-web/src/styles/variables.less';