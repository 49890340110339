.card {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: fit-content;
	max-height: calc(100vh - 70px);
	height: 627px;
	max-width: 100%;
	width: 394px;
	padding: 69px 45px 63px;
	margin: auto;
	border-radius: 4px;
	border: 1px solid #383838;
	background: rgba(12, 12, 12, 0.4);
	backdrop-filter: blur(2px);
	text-align: center;

	@media only screen and (max-width: (@screen-mobile-max)) {
		padding: 20px;
		padding-top: 57px;
	}

	.header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 25px;
	}
}
.logo {
	margin-bottom: 33px;
}
.title {
	color: @white;
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 6px;
	display: flex;
	align-items: center;
	gap: 12px;
}
.description {
	color: @white;
	font-size: 16px;
	font-weight: 400;
	display: flex;
	align-items: center;
	gap: 9px;
}
.steps {
	display: flex;
	padding: 0 18px;
	margin-top: 15px;
	margin-bottom: 36px;

	.step {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 19px;
		height: 20px;
		background: #2b2b2b;
		border-radius: 50%;
		color: @white;
		font-size: 16px;
		font-weight: 400;

		&.active {
			border: 1px solid @white;
		}
		&.passed {
			background: @primary-color;
		}
	}
	.line {
		position: relative;
		width: 100%;
		&::after {
			content: '';
			position: absolute;
			top: calc(50% - 1.5px);
			left: 0;
			width: 100%;
			border-bottom: 3px solid #2b2b2b;
		}
		&.passed {
			&::after {
				border-color: @primary-color;
			}
		}
	}
}
.label {
	display: flex;
	justify-content: center;
	align-items: center;
	column-gap: 21px;
	width: 100%;
	text-align: center;
	color: @white;
	font-size: 12px;
	font-weight: 400;
}
.form {
	width: 100%;
	text-align: left;
	margin-top: 30px;
	margin-bottom: 21px;

	.password-rules {
		color: #686868;
		font-size: 10px;
		font-weight: 400;
	}

	.phone-number {
		display: flex;
		align-items: center;

		background: #000000;
		color: #ffffff;
		font-size: 12px;
		font-weight: 700;
		box-shadow: none;
		outline: none;

		// width: Fixed (282px)px;
		// height: Fixed (38px)px;
		// top: 253px;
		// left: 43px;
		padding: 0px;
		// gap: 113px;
		border-radius: 49px;
		border: 1px solid #393c3f;

		.code,
		.number {
			input {
				padding: 11px;
				background: none;
				border: none;
			}
		}
		.code {
			min-width: 48px;
			input {
				padding-left: 15px;
			}
		}
		.number {
			width: 100%;
			input {
				padding-right: 15px;
			}
		}
		.line {
			height: 22px;
			width: 1px;
			background: #686868;
		}
	}

	.submit-btn {
		margin-top: 23px;
	}
}
.form-otp {
	.submit-btn {
		margin-top: 155px;
		margin-bottom: 37px;
	}
}
.captcha {
	position: relative;
	margin: 22px 0;
}
.forgot-password {
	font-size: 12px;
	font-weight: 400;
}
.divider {
	display: flex;
	margin-top: 38px;
	margin-bottom: 21px;

	span {
		color: #686868;
		font-size: 12px;
		font-weight: 700;
		margin: 0 15px;
	}
	.line {
		position: relative;
		width: 100%;
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			border-bottom: 1px solid #686868;
		}
	}
}
.recovery-key {
	max-width: 100%;
	width: 302px;
	padding: 15px;
	border-radius: 4px;
	background: #8b8b8b1a;
	font-size: 20px;
	font-weight: 700;
	line-height: 26.04px;
	text-align: center;
	max-height: 110px;
	overflow: auto;
}
.create-account {
	display: flex;
	justify-content: center;
	gap: 6px;
	font-size: 12px;
	margin-top: auto;

	span {
		color: @white;
		font-weight: 400;
	}
}
.agreement {
	font-size: 12px;
	font-weight: 400;
	line-height: 15.62px;
	letter-spacing: 0.20000000298023224px;
	text-align: center;
	color: #858584;
	margin-top: 36px;

	a {
		color: #d9d9d9;
	}
}
.login-options {
	width: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 14px;
	margin-top: 39px;

	.login-opt-button {
		width: 100% !important;
		border-radius: 6px !important;
		border: 1px solid #393c3f !important;
		background: #000 !important;

		.icon {
		}
		span {
			color: @white;
			font-size: 12px;
			font-weight: 700;
			margin-left: 8px;
		}
	}
}


@import '/home/eff/app/creoplay-web/src/styles/variables.less';